import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<inlineCode parentName="p">{`SimpleControlPanel`}</inlineCode>{` is a basic animation playback user interface.`}</p>
    <p>{`It is intended for use in the `}<inlineCode parentName="p">{`panel`}</inlineCode>{` property of the `}<inlineCode parentName="p">{`Controller`}</inlineCode>{`, where it is rendered by default.
The `}<inlineCode parentName="p">{`SimpleControlPanel`}</inlineCode>{` is unstyled; its purpose is to be an effortless demo component that can be replaced with a more aesthetically pleasing panel later.`}</p>
    <h2 {...{
      "id": "import"
    }}>{`Import`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { SimpleControlPanel } from "react-ensemble";
`}</code></pre>
    <h2 {...{
      "id": "interface"
    }}>{`Interface`}</h2>
    <p><inlineCode parentName="p">{`SimpleControlPanel`}</inlineCode>{` is a React component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const SimpleControlPanel: React.FC<ControlPanelProps>;
`}</code></pre>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <ul>
      <li parentName="ul">{`Type: `}<a parentName="li" {...{
          "href": "/docs/api/types#controlpanelprops"
        }}><inlineCode parentName="a">{`ControlPanelProps`}</inlineCode></a></li>
    </ul>
    <p>{`See `}<a parentName="p" {...{
        "href": "/docs/api/types#controlpanelprops"
      }}><inlineCode parentName="a">{`ControlPanelProps`}</inlineCode></a>{`.`}</p>
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  const [tick, setTick] = React.useState(0);
  const [playing, setPlaying] = React.useState(false);
  const [direction, setDirection] = React.useState(Direction.Normal);

  // Note: You most likely wouldn't ever render SimpleControlPanel
  // directly like this. Controller manages all these props in a
  // similar way when you use SimpleControlPanel as its panel prop.
  // See the Controller API docs for more information.
  return (
    <SimpleControlPanel
      tick={tick}
      length={1000}
      playing={playing}
      direction={direction}
      setTick={t => setTick(t)}
      play={() => setPlaying(true)}
      pause={() => setPlaying(false)}
      fastForward={() =>
        setDirection(prev =>
          prev === Direction.FastForward
            ? Direction.Normal
            : Direction.FastForward
        )
      }
      reverse={() =>
        setDirection(prev =>
          prev === Direction.Reverse ? Direction.Normal : Direction.Reverse
        )
      }
    />
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      